import { USER_ROLES } from '../../services/user';
import { usePermission } from '../../hooks/useUserPermission';

const permissions = {
  [USER_ROLES.admin]: {
    template: { tableHidden: true },
    name: { tableHidden: true },
    selected: { tableHidden: true },
    general: {
      tableActions: ['view'],
    },
  },
  [USER_ROLES.restaurant]: {
    restaurant: { hidden: true },
    restaurantName: { tableHidden: true },
    responses: { tableHidden: true },
    whatsApp: { tableHidden: true },
    general: {
      tableActions: ['view', 'edit', 'remove'],
      customActions: true,
    },
  },
  [USER_ROLES.franchise]: {
    restaurant: { hidden: true },
    restaurantName: { tableHidden: true },
    responses: { tableHidden: true },
    whatsApp: { tableHidden: true },
    general: {
      tableActions: ['view', 'edit', 'remove'],
      customActions: true,
    },
  },
  [USER_ROLES.master]: {
    restaurant: { hidden: true },
    restaurantName: { tableHidden: true },
    responses: { tableHidden: true },
    whatsApp: { tableHidden: true },
    general: {
      tableActions: ['view', 'edit', 'remove'],
      customActions: true,
    },
  },
};

const useUserPermission = () => usePermission(permissions);

export {
  useUserPermission,
};
