import React from 'react';
import {
  FileCopy as IconFileCopy,
  BusinessCenter as BusinessCenterIcon,
  Delete as RemoveIcon,
} from '@material-ui/icons';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ErrorIcon from '@material-ui/icons/Error';
import { BUDGET_STATUS } from '../../services/budget';

export const product = ({
  removeProduct, updateProduct, editProduct, isFranchise, isEditQuantity,
}) => [
  {
    align: 'left',
    name: 'Nome',
    key: 'productName',
  },
  {
    align: 'left',
    name: 'Quantidade',
    key: 'quantity',
    type: 'input',
    onChange: (e, row) => updateProduct(e.target.value, row, 'quantity'),
  },
  {
    align: 'left',
    name: 'Tipo de unidade',
    key: 'unit',
    type: 'select',
    options: [
      { value: 'kg', label: 'Kg' },
      { value: 'un', label: 'Un' },
    ],
    onChange: (e, row) => updateProduct(e.target.value, row, 'unit'),
  },
  {
    align: 'obs',
    name: 'Observação',
    key: 'observation',
    type: !isEditQuantity ? 'textarea' : 'text',
    onChange: (e, row) => updateProduct(e.target.value, row, 'observation'),
    cellStyle: {
      minWidth: 300,
      maxWidth: 500,
    },
  },
  {
    align: 'center',
    name: 'Ações',
    key: 'actions',
    type: 'action',
    buttons: [''],
    tableHidden: isEditQuantity,
    customButtons: [
      {
        id: 'id',
        icon: <BusinessCenterIcon />,
        action: editProduct,
        hidden: () => isFranchise,
        propLink: 'id',
      },
      {
        id: 'id',
        icon: <RemoveIcon />,
        action: removeProduct,
        propLink: 'id',
      },
    ],
  },
];

export const generalProductsTable = ({ editProduct, isEditQuantity, isMasterAndRequested }) => [
  {
    align: 'left',
    name: 'Nome',
    key: 'productName',
  },
  {
    align: 'left',
    name: 'Quantidade',
    key: 'quantity',
  },
  {
    align: 'left',
    name: 'Tipo de unidade',
    key: 'unit',
  },
  {
    align: 'obs',
    name: 'Observação',
    key: 'observation',
    cellStyle: {
      minWidth: 180,
      maxWidth: 200,
      whiteSpace: 'unset',
      wordBreak: 'break-word',
    },
  },
  {
    align: 'center',
    name: 'Ações',
    key: 'actions',
    type: 'action',
    tableHidden: isEditQuantity || !isMasterAndRequested,
    buttons: [''],
    customButtons: [
      {
        id: 'id',
        icon: <BusinessCenterIcon />,
        action: editProduct,
        propLink: 'id',
      },
    ],
  },
];

export default ({
  handleDuplicate,
  sendReminders,
  toggleTemplate,
  userPermission,
  hasRequest,
  isFranchise,
  isParent,
},
) => [
  {
    align: 'left',
    key: 'template',
    name: 'Modelo',
    sortable: true,
    type: 'clickable',
    onClickCell: toggleTemplate,
    headerStyle: { maxWidth: 100 },
    tableHidden: userPermission('template', 'tableHidden'),
  },
  {
    align: 'left',
    name: 'Nome',
    key: 'name',
    sortable: true,
    tableHidden: userPermission('name', 'tableHidden'),
  },
  {
    align: 'left',
    name: 'Restaurante',
    key: 'restaurantName',
    sortable: true,
    tableHidden: userPermission('restaurantName', 'tableHidden'),
  },
  {
    align: 'left',
    name: 'Data de criação',
    key: 'createdAt',
    sortable: true,
    headerStyle: { minWidth: 200 },
  },
  {
    align: 'left',
    name: 'Data limite',
    key: 'endsAt',
    sortable: true,
    headerStyle: { minWidth: 160 },
  },
  {
    align: 'status',
    name: 'Status',
    key: 'statusLabel',
    sortable: true,
  },
  {
    align: 'left',
    name: 'Respostas',
    key: 'responses',
    tableHidden: userPermission('responses', 'tableHidden'),
  },
  {
    align: 'center',
    name: 'Enviar Lembrete',
    key: 'whatsapp',
    type: 'action',
    tableHidden: userPermission('whatsApp', 'tableHidden'),
    buttons: [],
    customButtons: [
      {
        id: 'whatsapp',
        icon: row => (row.status === BUDGET_STATUS.OPEN && !row.sentWhatsAppReminder)
          && <WhatsAppIcon />,
        propLink: 'whatsappLink',
        action: row => sendReminders(row),
      },
    ],
  },
  {
    align: 'center',
    name: 'Ações',
    key: 'actions',
    type: 'action',
    isViewable: (({ status }) => {
      if (isFranchise || isParent) {
        return status !== BUDGET_STATUS.REQUESTED;
      }
      return true;
    }),
    isRemovable: ({ status, idMainBudget }) => {
      if (isFranchise && !!idMainBudget) {
        return status === BUDGET_STATUS.SAVED;
      }
      if (isFranchise && !idMainBudget) {
        return false;
      }
      return status === BUDGET_STATUS.OPEN
     || status === BUDGET_STATUS.REQUESTED;
    },
    isEditable: ({ status, idMainBudget }) => {
      if (isFranchise && !!idMainBudget) {
        return status === BUDGET_STATUS.REQUESTED || status === BUDGET_STATUS.SAVED;
      }

      if (isFranchise && !idMainBudget) {
        return status === BUDGET_STATUS.REQUESTED;
      }

      return status === BUDGET_STATUS.SAVED
     || status === BUDGET_STATUS.REQUESTED;
    },
    buttons: userPermission('general', 'tableActions'),
    customButtons: userPermission('general', 'customActions') ? [
      {
        id: 'id',
        icon: <IconFileCopy />,
        action: handleDuplicate,
        propLink: 'id',
        hidden: ({ status }) => {
          if (isFranchise) {
            return status === BUDGET_STATUS.REQUESTED
            || status === BUDGET_STATUS.SAVED || !hasRequest;
          }
          return isParent && hasRequest;
        },
      },
    ] : [],
  },
];

export const tableBudgetResponseTotal = [
  {
    align: 'center',
    key: 'responses',
    name: 'Respostas',
  },
  {
    align: 'center',
    key: 'suppliers',
    name: 'Quantidade de pedidos',
  },
  {
    align: 'center',
    key: 'total',
    name: 'Total do pedido',
  },

  {
    align: 'center',
    key: 'lower',
    name: 'Total mais barato',
  },
  {
    align: 'center',
    key: 'diff',
    name: 'Total diferença',
  },
  {
    align: 'center',
    key: 'high',
    name: 'Total mais caro',
  },
  {
    align: 'center',
    key: 'economize',
    name: 'Total economizado',
  },
];


export const tableBudgetResponseProducts = [
  {
    align: 'left',
    key: 'name',
    name: 'Produto',
    sortable: true,
  },
  {
    align: 'left',
    key: 'quantity',
    name: 'Quantidade',
  },
  {
    align: 'left',
    key: 'supplier',
    name: 'Fornecedor',
    sortable: true,
  },
  {
    align: 'left',
    key: 'observation',
    name: 'Observação',
    cellStyle: {
      minWidth: 180,
      maxWidth: 200,
      whiteSpace: 'unset',
      wordBreak: 'break-word',
    },
  },
  {
    align: 'left',
    key: 'price',
    name: 'Preço',
    sortable: true,
  },
  {
    align: 'left',
    key: 'totalPrice',
    name: 'Preço total',
  },
  {
    align: 'right',
    key: 'lowestPrice',
    name: 'Menor preço',
  },
  {
    align: 'right',
    key: 'diffPrice',
    name: 'Diferença de preço',
    sortable: true,
  },
  {
    align: 'left',
    key: 'paymentTerm',
    name: 'Prazo de pagamento',
  },
  {
    align: 'left',
    key: 'deadline',
    name: 'Prazo de entrega',
    headerStyle: { minWidth: 120 },
  },
  {
    align: 'left',
    key: 'validity',
    name: 'Validade da cotação',
    headerStyle: { minWidth: 140 },
  },
];


export const tableChildBudgetResponseProducts = [
  {
    align: 'left',
    key: 'name',
    name: 'Produto',
    sortable: true,
  },
  {
    align: 'left',
    key: 'quantity',
    name: 'Quantidade',
  },
  {
    align: 'left',
    key: 'observation',
    name: 'Observação',
    cellStyle: {
      minWidth: 180,
      maxWidth: 200,
      whiteSpace: 'unset',
      wordBreak: 'break-word',
    },
  },
];

export const tableBudgetResponseProductsSelect = (userPermission, budgetStatus) => [
  {
    align: 'center',
    key: 'selected',
    type: 'checkbox',
    tableHidden: userPermission('selected', 'tableHidden') || budgetStatus !== BUDGET_STATUS.FINALIZED,
    onChange: () => {},
  },
  {
    align: 'left',
    key: 'supplier',
    name: 'Fornecedor',
    sortable: true,
  },
  {
    align: 'left',
    key: 'brand',
    name: 'Marca/Gramatura',
  },
  {
    align: 'left',
    key: 'observation',
    name: 'Observação',
    cellStyle: {
      minWidth: 180,
      maxWidth: 200,
      whiteSpace: 'unset',
      wordBreak: 'break-word',
    },
  },
  {
    align: 'left',
    key: 'price',
    name: 'Preço',
    sortable: true,
  },
  {
    align: 'left',
    key: 'priceTotal',
    name: 'Preço total',
    sortable: true,
  },
  {
    align: 'left',
    key: 'minOrder',
    name: 'Pedido Mínimo',
    sortable: true,
  },
  {
    align: 'right',
    key: 'diffPrice',
    name: 'Diferença de preço',
    sortable: true,
  },
  {
    align: 'left',
    key: 'paymentTerm',
    name: 'Prazo de pagamento',
  },
  {
    align: 'left',
    key: 'deadline',
    name: 'Prazo de entrega',
  },
  {
    align: 'left',
    key: 'validity',
    name: 'Validade da cotação',
  },
];


export const tableResponseSuppliers = ({
  failedSuppliers, sendBudgetToSupplier, isAdmin,
}) => [
  {
    align: 'left',
    name: 'Nome da empresa',
    key: 'name',
    sortable: true,
  },
  {
    align: 'left',
    name: 'Nome do representante',
    key: 'contactName',
    sortable: true,
  },
  {
    align: 'left',
    name: 'Telefone',
    key: 'phone',
  },
  {
    align: 'center',
    name: 'Enviar por WhatsApp',
    key: 'whatsapp',
    type: 'action',
    tableHidden: !isAdmin,
    buttons: [],
    customButtons: [
      {
        id: 'whatsapp',
        icon: row => (
          <>
            {failedSuppliers.includes(row.id) && <ErrorIcon color="error" />}
            <WhatsAppIcon />
          </>
        ),
        propLink: 'whatsappLink',
        action: row => sendBudgetToSupplier(row),
      },
    ],
  },
  {
    align: 'left',
    name: 'Pedido mínimo',
    key: 'minimumValue',
    sortable: true,
  },
  {
    align: 'left',
    name: 'Data da resposta',
    key: 'updatedAt',
    sortable: true,
  },
  {
    align: 'left',
    name: 'Observação Geral',
    key: 'observation',
    cellStyle: {
      minWidth: 180,
      maxWidth: 200,
      whiteSpace: 'unset',
      wordBreak: 'break-word',
    },
  },
];

export const tableResponseFranchisees = [
  {
    align: 'left',
    name: 'Nome do restaurante',
    key: 'name',
    sortable: true,
  },
  {
    align: 'left',
    name: 'Nome do representante',
    key: 'contactName',
    sortable: true,
  },
  {
    align: 'left',
    name: 'Telefone',
    key: 'phone',
  },
  {
    align: 'left',
    name: 'Data da resposta',
    key: 'updatedAt',
    sortable: true,
  },
  {
    align: 'left',
    name: 'Horário de entrega',
    key: 'deliveryTime',
    cellStyle: {
      minWidth: 180,
      maxWidth: 200,
      whiteSpace: 'unset',
      wordBreak: 'break-word',
    },
  },
  {
    align: 'left',
    name: 'Status',
    key: 'statusLabel',
  },
  {
    align: 'center',
    name: 'Ações',
    key: 'actions',
    type: 'action',
    isEditable: ({ status }) => status === BUDGET_STATUS.SENT,
    isViewable: ({ status }) => status === BUDGET_STATUS.SAVED || status === BUDGET_STATUS.SENT,
    buttons: ['view', 'edit'],
  },
];
