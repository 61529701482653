import Axios from 'axios';
import { formatPhoneToInternational } from '../../utils/formatters';
import config from '../../config';

const baseUrl = `https://us-central1-${config.env.firebaseEnv.projectId}.cloudfunctions.net`;

/**
 * Envia uma mensagem para o fornecedor.
 * @param {Object} params - Os parâmetros da função.
 * @param {string} params.restaurantName - O nome do restaurante.
 * @param {string} params.budgetId - O ID do orçamento.
 * @param {string} params.supplierName - O nome do fornecedor.
 * @param {string} params.supplierPhone - O telefone do fornecedor.
 * @param {boolean} params.isReminder - Se o tipo da mensagem é lembrete.
 * @returns {Promise<void>} - Uma promise que resolve quando a mensagem for enviada.
 */
export const sendMessageToSupplier = async ({
  restaurantName, budgetId, supplierName, supplierPhone, isReminder,

}) => {
  const response = await Axios.post(`${baseUrl}/sendMessageToSupplier`, {
    restaurantName,
    budgetId,
    supplierName,
    supplierPhone: formatPhoneToInternational(supplierPhone),
    isReminder,
  },
  );

  return response.data;
};
